import React, { Component } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

class Layout extends Component {
    render() {
        return(
            <React.Fragment>
                <Header />
                <div style={{marginTop: '3.2rem'}}>
                    <main style={{padding: '0 2rem'}}>{this.props.children}</main>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default Layout
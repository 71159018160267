import React from 'react';
import { Link } from 'react-router-dom';

import classes from './Button.module.css';

const button = ( props ) => {
     return (
        <div className={classes.Button}>
            <Link to="/blog" >{props.children}</Link>
        </div>     
     );
};

export default button;
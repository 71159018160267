import React, { Component } from 'react';
import Me from '../../components/AboutMe/AboutMe';
// Bio Component
import ContentText from '../../components/Content/ContentText/ContentText';

import classes from './AboutMe.module.css';

class AboutMe extends Component {
    render() {
        const title = 'Sobre mí'
        const text = `Hola, ¿qué tal? Mi nombre es Alfredo Lugo, soy ingeniero en tecnologías electrónicas (ITE) y actualmente radico en la ciudad de Frankfurt, Alemania.
                      Si lo que buscas es un sitio en el que puedas aprender de los errores, éxitos y sobre todo el camino que toma llegar a cumplir una meta o un sueño, entonces haz llegado al sitio correcto.`
        const bioTitle = 'biografía';
        const bioText = `Mis primeros pasos como profesionista en el campo de la electrónica se remontan a mis estudios en preparatoria, en mi caso CETis 128 en la ciudad de Nogales, Sonora de donde soy originario. Decidí estudiar electrónica ya que mi intención sigue siendo la de mejorar este mundo.
                         Gracias al apoyo de mi familia tuve la oportunidad de estudiar en una de las universidades mas prestigiosas de México y obtener mi título como Ingeniero en Tecnologías Electrónicas (ITE).
                         Dos semanas antes de concluir con mis estudios superiores la empresa National Instruments México se presentó en mi universidad buscando dos candidatos para la posición de Ingeniero de Aplicaciones. Tras una serie de 5 entrevistas, la presentación de uno de mis proyectos y haber competido contra cientos de personas logré obtener el puesto y como pocos contaba con un excelente trabajo incluso antes de graduarme.
                         El camino en National Instruments México fue muy divertido y desafiante, ya que los ingenieros que trabajan en NI no me dejaran metir y se encuentran en un aprendizaje continuo muy supeior al que otras empresas ofrecen.`;
        
        return (
            <React.Fragment>
                <div style={{padding: '2rem'}}>
                    <div className={classes['container']}>
                        <div className={classes['container-content']}>
                            <Me title={title} text={text} />
                        </div>
                    </div>

                    <div className={classes['container']}>
                        <div className={classes['container-content']}>
                            <ContentText title={bioTitle} text={bioText} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AboutMe;
import React from 'react';
import Icon from '../Icon/Icon';
import FormMailList from '../Forms/FormMailList/FormMailList';
import classes from './Footer.module.css';

const items = [
    {id:1, link:'https://twitter.com/rEdonE_13', title:'Visitame en Twitter', desc:'Twitter'},
    {id:2, link:'https://www.linkedin.com/in/jos%C3%A9-alfredo-lugo-vega-50710435/', title:'Visitame en LinkedIn', desc:'LinkedIn'},
    {id:3, link:'https://instagram.com/rEdonE_13', title:'Visitame en Instagram', desc:'Instagram'},
    {id:4, link:'https://github.com/rEdonE13', title:'Visitame en Github', desc:'Github'},
    {id:5, link:'https://facebook.com/rEdonE_13', title:'Visitame en Facebook', desc:'Facebook'}
];

const socialMediaItems = items.map( item => (
    <li key={item.id} className={classes['list-item']}>
        <a  href={item.link}
            className={classes['list-link']}
            title={item.title}
            target="_blank"
            rel="noopener noreferrer">
                <span><Icon name={item.desc}/></span>
        </a>
    </li>
));

const footer = ( props ) => {
    const date = new Date()
     return (
         <footer className={classes['footer']}>
             <div className={classes['wrapper']}>
                <aside>
                    <h4 className={classes['heading']}>Sígueme</h4>
                    <ul className={classes['list']}>
                        { socialMediaItems }
                    </ul>
                </aside>

                <aside>
                    <FormMailList />
                </aside>
             </div>
             <div className={classes['copyright']}><span>Copyright &copy; {date.getFullYear()} rEdonE13</span></div>
         </footer>
     );
};

export default footer;
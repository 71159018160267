import React from 'react';

const blogItem = ( props ) => {
     return (
         <div>
             Blog Item
         </div>
     );
};

export default blogItem;
import React from 'react';
import FormContactMe from '../../components/Forms/FormContactMe/FormContactMe';

import classes from './ContactMe.module.css';

const contactMe = ( props ) => {
     return (
         <div className={classes['ContactMe']}>
             <FormContactMe />
         </div>
     );
};

export default contactMe;
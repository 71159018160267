import React from 'react';
import classes from './AboutMe.module.css';

const aboutMe = ( props ) => {
    const img = {
        src: require('../../assets/images/me.jpg'),
        desc: 'foto de perfil'
    };

    const title = <h3 className={classes.Title}>{ props.title }</h3>;
    const paragraphs = props.text.split('\n');
    const text = paragraphs.map( (paragraph, index) => (
        <p className={classes.Text} key={index}>{paragraph.trim()}</p>
    ));

    return (
        <div className={classes.AboutMe}>
            <img className={classes.Photo} src={img.src} alt={img.desc}/>
            <div className={classes.Content}>
                {title}
                {text}
            </div>
        </div>
     );
};

export default aboutMe;
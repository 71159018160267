import React from 'react';
import classes from './FormContactMe.module.css';

const formContactMe = (  ) => {
     return (
         <div className={classes['FormContactMe']}>
             <h3 className={classes['title']}>Formulario de contacto</h3>
            <form action="" method="post">
                
                <div className={classes['input']}>
                    <label htmlFor="name" className={classes['label']}>nombre <span>&#42;</span></label>
                    <input type="text" name="name"/>
                </div>

                <div className={classes['input']}>
                    <label htmlFor="email" className={classes['label']}>correo electrónico <span>&#42;</span></label>
                    <input type="email" name="email"/>
                </div>

                <div className={classes['input']}>
                    <label htmlFor="msg" className={classes['label']}>mensaje <span>&#42;</span></label>
                    <textarea name="msg" cols="30" rows="10" className={classes['msg']}></textarea>
                </div>

                <button type="submit">enviar</button>
            </form>
        </div>
     );
};

export default formContactMe;
import React from 'react';
import classes from './HeaderTitle.module.css';

const headerTitle = ( props ) => {
     return (
        <div className={classes['site-title-wrapper']}>
            <h1 className={classes['site-title']}>
                <a href="/" rel="home" className={classes['link']}>rEdonE13</a>
            </h1>
            <div className={classes['site-description']}>La vida es muy corta</div>
        </div>                
     );
};

export default headerTitle;
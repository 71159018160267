import React, { Component } from 'react'
import HeaderHero from '../../components/Header/HeaderHero/HeaderHero';
import CategoryPreview from '../../components/Category/CategoryPreview/CategoryPreview';
import Button from '../../components/UI/Button/Button';

import classes from './Home.module.css';

class Home extends Component {
    render() {
        const categories = [
            {id: 1, link: '/', img: require('../../assets/images/pexels-photo-132700-1024x682.jpeg'), title: 'Desarrollo', desc: 'Pasar una idea del plano mental al plano físico no es tarea fácil. y muchas veces nos ponemos excusas tales como: "Es que no tengo dinero", "Es que no tengo tiempo", "Es que no se programar", "Es que... es que" ¡Es que nada!, lo mas difícil es solo empezar pero una vez sobre la marcha todo se va volviendo mas fácil, es por eso que aprovecharé este espacio para compartir mis propias experiencias.'},
            {id: 2, link: '/', img: require('../../assets/images/working.jpeg'), title: 'Trabajo', desc: 'La ética de trabajo en Alemania es muy distinta a la Mexicana, por lo que quiero compartir todo lo que he aprendido al respecto, así como lo que aprenderé en un futuro y espero que tomes lo mejor de cada una y las adaptes a tu propia ética de trabajo.'},
            {id: 3, link: '/', img: require('../../assets/images/traveling.jpeg'), title: 'Conozcamos El mundo', desc: 'En esta sección compartiré contigo mis experiencias trabajando desde otras ciudades así como otros paises. Aquí podrás ver que tan caro y efectivo puede llegar hacer algo similar. Te llevaré desde recorridos por las ciudades, bares, restaurantes y todo mientras se trabaja remotamente.'}
        ];

        const categoryItemsPreview = categories.map( category => (
            <CategoryPreview link={category.link} title={category.title} desc={category.desc} imgSrc={category.img} key={category.id}/>
        ));
        return (
            <React.Fragment>
                {/* <HeaderHero /> */}
                <div className={classes['area-content']}>
                    <div className={classes['row-content']}>
                        { categoryItemsPreview }
                    </div>

                    <div className={classes['row-content']}>
                        <div style={{marginRight: '6rem', flex: '1', fontSize: '1.9rem'}}>
                            <h3>Mi Meta</h3>
                            <p>En esta web estaré compartiendo el desarrollo de los diferentes proyectos que estoy 
                                desarrollando, así como mi experiencia al hacerlo remotamente al mismo tiempo que voy conociendo 
                                el mundo y gente de diferentes países con quienes he creado un lazo de amistad y con algunos otros 
                                un lazo de posible sociedades en un futuro no muy lejano. Para mi el mundo sigue siendo un enorme 
                                parque de diversiones y quiero ayudarte a que lo recuerdes.</p>
                        </div>
                        <div style={{flex: '0 0 auto', borderRadius: '.5rem'}}>
                            <img src={require('../../assets/images/android-landscape.jpg')} alt="android" width="400rem"/>
                        </div>
                    </div>

                    <div className={classes['row-content']}>
                        <div>
                            <div style={{marginBottom: '2rem'}}>
                                <h3>Siguientes pasos</h3>
                                <p>Bueno sin mas te invito a que le des un vistazo a la sección del Blog o que navegues por las distintas categorías que he creado.</p>
                            </div>
                            <Button>Quiero conocer tu blog</Button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Home;
import axios from 'axios';

const instance = axios.create({
    baseURL: 'http://localhost',
    timeout: 5000,
    headers: {'Access-Control-Allow-Origin': '*'},
    withCredentials: false,
    
});

export default instance;
import React from 'react';

import classes from './CategoryPreview.module.css';

const categoryPreview = ( props ) => {
    return (
        <article className={classes['category-preview']}>
            <img src={props.imgSrc} alt="img"/>
            <h3 className={classes['title']}>
                <span><a href={props.link} className={classes['link']}>{props.title}</a></span>
            </h3>
            <p>{props.desc}</p>
            <a href={props.link} className={classes['more']}>Más información</a>
        </article>
     );
};

export default categoryPreview;
import React from 'react';
import HeaderTitle from './HeaderTitle/HeaderTitle';
import Navigation from './Navigation/Navigation';
import classes from './Header.module.css';

const header = ( props ) => {
     return (
         <header className={classes['header']}>
             <div className={classes['site-header-wrapper']}>
                <div className={classes['site-header']}>
                    <HeaderTitle />
                    <Navigation />
                </div>
             </div>
         </header>
     );
};

export default header;
import React, { Component } from 'react';
import Layout from '../src/components/Layout/Layout';
import Home from '../src/containers/Home/Home';
import Blog from '../src/containers/Blog/Blog';
import AboutMe from '../src/containers/AboutMe/AboutMe';
import ContactMe from '../src/containers/ContactMe/ContactMe';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Layout>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/blog" component={Blog} />
            <Route path="/sobre-mi" component={AboutMe} />
            <Route path="/contactame" component={ContactMe} />
            <Route render={() => <div>Página no encontrada!</div>} />
          </Switch>
        </Layout>
      </BrowserRouter>
    );
  }
}

export default App;

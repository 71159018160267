import React, { Component } from 'react';
import BlogItems from '../../components/Blog/BlogItems/BlogItems';

import classes from './Blog.module.css';

class Blog extends Component {
    render() {
        return (
            <div className={classes.Blog}>
                <h2>Blog</h2>
                <BlogItems />
            </div>
        );
    }
}

export default Blog;
import React from 'react';
import classes from './FormMailList.module.css';

const formMailList = ( props ) => {
     return (
         <React.Fragment>
            <h4 className={classes['heading']}>Avisame cuando publiques algo nuevo</h4>
            <form action="" className={classes['form-mail-list']}>
                <input type="email" name="email" placeholder="Correo electrónico"/>
                <button type="submit">Avisame</button>
            </form>
         </React.Fragment>
     );
};

export default formMailList;
import React, { useEffect } from 'react';
import BlogItem from './BlogItem/BlogItem';

import axios from '../../../axios'

const BlogItems = ( props ) => {
    useEffect( ()=>{
        axios.get('/posts/11')
            .then(res => console.log(res))
            .catch(err => console.log(err))
     });
     return (
         <div>
            <h2>Blog Items</h2>
            <BlogItem />
         </div>
     );
};

export default BlogItems;
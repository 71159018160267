import React from 'react';
import classes from './ContentText.module.css';

const contentText = ( props ) => {
    const title = <h3 className={classes['title']}>{ props.title }</h3>;
    const paragraphs = props.text.split('\n');
    const text = paragraphs.map( (paragraph, index) => (
        <p className={classes['text']} key={index}>{paragraph.trim()}</p>
    ));
    
     return (
        <div className={classes['content-text']}>
            { title }
            { text }
        </div>
     );
};

export default contentText;
import React from 'react';
import classes from './Icon.module.css';

import { ReactComponent as Facebook } from '../../assets/iconos/facebook.svg';
import { ReactComponent as Twitter } from '../../assets/iconos/twitter.svg';
import { ReactComponent as Instagram } from '../../assets/iconos/instagram.svg';
import { ReactComponent as Github } from '../../assets/iconos/github.svg';
import { ReactComponent as LinkedIn } from '../../assets/iconos/linkedin.svg';

const icon = ( props ) => {
     switch (props.name.toLowerCase()) {
        case 'facebook':
            return <Facebook className={classes['icon']}/>;
        case 'twitter':
            return <Twitter className={classes['icon']}/>;
        case 'instagram':
            return <Instagram className={classes['icon']}/>;
        case 'github':
            return <Github className={classes['icon']}/>;
        case 'linkedin':
            return <LinkedIn className={classes['icon']}/>;
        default:
            return <div />;
     }
};

export default icon;
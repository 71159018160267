import React from 'react';
import NavigationItem from './NavigationItem/NavigationItem';
import classes from './Navigation.module.css';

const navItems = [
    {link: '/', desc: 'inicio'},
    {link: '/blog', desc: 'blog'},
    {link: '/sobre-mi', desc: 'sobre mí'},
    {link: '/contactame', desc: 'contáctame'}
]

const navigationItems = navItems.map( (navItem, index) => (
    <NavigationItem link={navItem.link} key={index}>{navItem.desc}</NavigationItem>
))

const nav = ( props ) => {
     return (
        <nav className={classes.Navigation}>
            <ul className={classes.NavigationItems}>
                { navigationItems }
            </ul>
        </nav>
     );
};

export default nav;